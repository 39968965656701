<template>
  <div class="content">
    <div class="content__party">
      <h1 class="title title--big title--color">{{ pageTitle }}: Архив</h1>
      <div class="search">
        <v-select
          @input="searchClick"
          v-model="searchResult"
          :filterable="false"
          :options="searchOptions"
          @search="onSearch"
          label="id"
          placeholder="Поиск по партнерам (Название / ID)"
          class="select"
        >
          <template slot="open-indicator">
            <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                 fill="none">
              <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                    stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"></path>
            </svg>
          </template>
          <template slot="no-options">Введите свой запрос для поиска</template>
          <template slot="option" slot-scope="option">
            <div class="select__item d-center">
              {{ option.name }} (ID: {{ option.id }})
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <div class="selected d-center">{{ option.name }} (ID: {{ option.id }})</div>
          </template>
        </v-select>
      </div>
    </div>
    <div class="content__party content__party--start">
      <router-link
        :to="{ name: 'company-create' }"
        class="button button--light button--no-indent button--circle"
      >
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="9" cy="9" r="9" fill="#2B93E7"/>
          <path
            d="M11.9694 9.67347H9.67347V11.9694C9.67347 12.2219 9.46684 12.4286 9.21429 12.4286C8.96173 12.4286 8.7551 12.2219 8.7551 11.9694V9.67347H6.45918C6.20663 9.67347 6 9.46684 6 9.21429C6 8.96173 6.20663 8.7551 6.45918 8.7551H8.7551V6.45918C8.7551 6.20663 8.96173 6 9.21429 6C9.46684 6 9.67347 6.20663 9.67347 6.45918V8.7551H11.9694C12.2219 8.7551 12.4286 8.96173 12.4286 9.21429C12.4286 9.46684 12.2219 9.67347 11.9694 9.67347Z"
            fill="white"/>
        </svg>
        <span>Добавить партнера</span>
      </router-link>
    </div>
    <div class="content__party">
      <div class="table-limiter">
        <div class="table-limiter__text">Количество записей на странице:</div>
        <div class="table-limiter__select">
          <dropdown class="my-dropdown-toggle"
                    :options="limitOptions"
                    :selected="limitSelected"
                    @updateOption="updateLimiter"
                    :closeOnOutsideClick="true">
          </dropdown>
        </div>
      </div>
      <div class="table-limiter">
        <div class="table-limiter__text">
          Показаны записи <span>{{ returnEnd > 0 ? returnStart : 0 }} - {{ returnEnd }}</span> из <span>{{
            all
          }}</span>
        </div>
      </div>
    </div>
    <div v-if="!regionalAdmin" class="filters filters--indent">
      <div class="filters__block">
        <input
          @change="fetchPartners(1, 3, current_order, limitSelected.name, '', filters.region)"
          id="unregion3"
          type="radio"
          name="unregion"
          value=""
          v-model="filters.unregion"
          checked
        >
        <label for="unregion3" class="filters__item">Все</label>
        <input
          @change="fetchPartners(1, 3, current_order, limitSelected.name, true, filters.region)"
          id="unregion1"
          type="radio"
          name="unregion"
          value="true"
          v-model="filters.unregion"
        >
        <label for="unregion1" class="filters__item">Без региона</label>
        <input
          @change="fetchPartners(1, 3, current_order, limitSelected.name, false, filters.region)"
          id="unregion2"
          type="radio"
          name="unregion"
          value="false"
          v-model="filters.unregion"
        >
        <label for="unregion2" class="filters__item">С регионом</label>
      </div>
      <div class="filters__search">
        <v-select
          v-if="filters.unregion !== 'true'"
          @input="fetchPartners(1, 3, current_order, limitSelected.name, filters.unregion, filters.region)"
          v-model="filters.region"
          :filterable="false"
          :options="regions"
          :reduce="item => item.id"
          @search="onRegionSearch"
          label="id"
          placeholder="Выберите регион"
          class="select"
        >
          <template slot="open-indicator">
            <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
              <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
              <path
                d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                fill="#fff"></path>
            </svg>
          </template>
          <template slot="clear-indicator">
            <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
              <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
              <path
                d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                fill="#fff"></path>
            </svg>
          </template>
          <template slot="option" slot-scope="option">
            <div class="select__item">{{ option.name }}</div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <div class="d-center">{{ option.name }}</div>
          </template>
        </v-select>
      </div>
    </div>
    <v-client-table :data="partners" :columns="columns" :options="options" ref="partnersTable" class="table-default">
      <div slot="name" slot-scope="props" class="table-default__left">
        {{ props.row.name }}
      </div>
      <div slot="title" slot-scope="props" >
        <span v-if="props.row.title">{{ props.row.title }}</span>
        <span v-else class="table-default__error">Отсутствует!</span>
      </div>
      <div slot="logo_url" slot-scope="props" class="table-default__logo">
        <img v-if="props.row.logo_url" :src="props.row.logo_url" :alt="`${props.row.name} - logo`">
        <img v-else src="@/assets/img/company-plug.svg" alt="Plug">
      </div>
      <div slot="h__created_at" class="table-default__sortable table-default__sortable--desc" data-name="created_at" @click="getSort($event)">Дата создания</div>
      <div slot="created_at" slot-scope="props">
        {{ props.row.created_at.split(' ')[0] }}
      </div>
      <div slot="region_name" slot-scope="props">
        {{ props.row.region_name ? props.row.region_name : '-' }}
      </div>
      <div slot="tooltip" slot-scope="props">
        <v-popover
          placement="left-start"
        >
          <button class="tooltip-target b3" ref="trigger">
            <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9 4C10.1046 4 11 3.10457 11 2C11 0.89543 10.1046 0 9 0C7.89543 0 7 0.89543 7 2C7 3.10457 7.89543 4 9 4Z"
                fill="#2B93E7"/>
              <path
                d="M16 4C17.1046 4 18 3.10457 18 2C18 0.89543 17.1046 0 16 0C14.8954 0 14 0.89543 14 2C14 3.10457 14.8954 4 16 4Z"
                fill="#2B93E7"/>
              <path
                d="M2 4C3.10457 4 4 3.10457 4 2C4 0.89543 3.10457 0 2 0C0.89543 0 0 0.89543 0 2C0 3.10457 0.89543 4 2 4Z"
                fill="#2B93E7"/>
            </svg>
          </button>
          <template slot="popover">
            <ul class="tooltip__list">
              <li class="tooltip__item">
                <img src="@/assets/img/edit-icon.svg" alt="Edit icon">
                <a :href="`/admin/company/${props.row.id}`" class="tooltip__btn">Редактировать</a>
              </li>
              <!--              <li class="tooltip__item">-->
              <!--                <img src="@/assets/img/trash-icon.svg" alt="Delete icon">-->
              <!--                <button @click="deleteManager(props.row.id)" class="tooltip__btn">Удалить</button>-->
              <!--              </li>-->
            </ul>
          </template>
        </v-popover>
      </div>
    </v-client-table>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="clickPaginationCallback"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    >
    </paginate>
  </div>
</template>

<script>
import dropdown from 'vue-dropdowns';
import {debounce} from 'lodash';
import {eventBus} from '@/main';

export default {
  name: 'CompanyNew',
  props: ['pageTitle'],
  components: {dropdown},
  data() {
    return {
      columns: ['id', 'logo_url', 'name', 'title', 'discount_cnt', 'address_cnt', 'created_at', 'region_name', 'tooltip'],
      current_order: 'created_at',
      options: {
        headings: {
          id: 'ID',
          logo_url: 'Логотип',
          name: 'Организация',
          title: 'Заголовок',
          discount_cnt: 'Скидок',
          address_cnt: 'Адресов',
          created_at: 'Дата подключения',
          region_name: 'Регион',
          tooltip: ''
        },
        pagination: {show: false},
        toggleGroups: true,
        sortable: [],
        perPage: 20,
        texts: {
          filter: '',
          filterPlaceholder: 'Поиск по таблице',
          noResults: 'Нет подходящих записей',
          filterBy: '',
          loading: 'Загрузка',
          count: '',
          limit: 'Количество записей на странице:',
          page: 'Страница:'
        },
        rowClassCallback() {
          return 'table-default__row';
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
      },
      table_limit: 20,
      limitSelected: {name: 20},
      limitOptions: [
        {name: 20},
        {name: 40},
        {name: 60},
        {name: 80},
        {name: 100},
      ],
      searchResult: null,
      searchOptions: [],
      regions: [],
      filters: {
        region: '',
        unregion: '',
      },
      partners: [],
      all: null,
    };
  },
  computed: {
    regionalAdmin() {
      return this.$store.state.regionalAdmin;
    },
    lastPage() {
      return (this.pagination.page * this.limitSelected.name) >= this.all
    },
    returnStart() {
      if (!this.lastPage) return (this.pagination.page * this.partners.length) - (this.partners.length - 1)
      return (this.pagination.page * this.limitSelected.name) - this.limitSelected.name + 1
    },
    returnEnd() {
      if (!this.lastPage) return this.returnStart + this.partners.length - 1
      return this.all
    },
  },
  mounted() {
    this.filters = JSON.parse(localStorage.getItem('filtersArchive')) || this.filters
    this.limitSelected.name = 20;
    this.fetchPartners(1, 3, this.current_order, this.limitSelected.name, this.filters.unregion, this.filters.region);
    this.fetchRegions();
  },
  updated() {
    eventBus.$on('refreshFilter', () => { this.filters.unregion = ''; });
  },
  methods: {
    fetchRegions() {
      this.$store.dispatch('admin/GET_REGIONS')
        .then(response => {
          this.regions = response.data;
        })
        .catch(error => {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: `Ошибка:${error.message}`
          });
        });
    },
    fetchPartners(page = 1, statusCount, ordering, limit, unregion = '', region = '') {
      if (!region) region = '';
      if (this.filters.unregion === 'true' && this.filters.region) this.filters.region = '';
      localStorage.setItem('filtersArchive', JSON.stringify(this.filters))
      this.$store.dispatch('admin/company/GET_LIST', {page, statusCount, ordering, limit, unregion, region})
        .then(response => {
          this.partners = response.data.results
          this.pagination.pages = response.data.pages
          this.all = response.data.count
          this.pagination.count = response.data.count
        })
        .catch(error => {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: `Ошибка:${error.message}`
          });
        });
    },
    clickPaginationCallback(page) {
      this.fetchPartners(page, 3, this.current_order, this.limitSelected.name, this.filters.unregion, this.filters.region);
    },
    updateLimiter(data) {
      this.limitSelected.name = data.name
      this.$refs.partnersTable.setLimit(this.limitSelected.name);
      this.fetchPartners(1, 3, this.current_order, this.limitSelected.name, this.filters.unregion, this.filters.region);
    },
    searchClick() {
      const a = document.createElement('a');
      a.href = `/admin/company/${this.searchResult.id}`;
      a.style = 'display: none';
      a.click();
    },
    onSearch(search, loading) {
      loading(true);
      this.searchDelivery(loading, search, this);
    },
    searchDelivery: debounce((loading, search, vm) => {
      vm.$store.dispatch('admin/company/GET_QUERY', search).then(response => {
        vm.searchOptions = response.data.results;
        loading(false);
      });
    }, 350),
    onRegionSearch(search, loading) {
      loading(true);
      this.searchRegion(loading, search, this);
    },
    searchRegion: debounce((loading, search, vm) => {
      vm.$store.dispatch('admin/GET_REGIONS_QUERY', search).then(response => {
        vm.regions = response.data;
        loading(false);
      });
    }, 350),
    getSort(event) {
      const menuItemsAsc = document.getElementsByClassName('table-default__sortable--asc');
      menuItemsAsc.forEach((item) => {
        item.classList.remove('table-default__sortable--asc');
      });

      const menuItemsDesc = document.getElementsByClassName('table-default__sortable--desc');
      menuItemsDesc.forEach((item) => {
        item.classList.remove('table-default__sortable--desc');
      });

      if (this.current_order === event.target.dataset.name) {
        this.current_order = `-${event.target.dataset.name}`;

        const menuItems = document.getElementsByClassName('table-default__sortable--desc');
        menuItems.forEach((item) => {
          item.classList.remove('table-default__sortable--desc');
        });

        event.target.classList.add('table-default__sortable--asc');
      } else {
        this.current_order = event.target.dataset.name;

        const menuItems = document.getElementsByClassName('table-default__sortable--asc');
        menuItems.forEach((item) => {
          item.classList.remove('table-default__sortable--asc');
        });

        event.target.classList.add('table-default__sortable--desc');
      }

      this.fetchPartners(1, 3, this.current_order, this.limitSelected.name, this.filters.unregion, this.filters.region);
    },
  },
};
</script>
